import {
  SERVICE_REPORT_OFFLINE_STATUS,
  ServiceReportDetailDetail,
  ServiceReportDetailDraft,
} from "../../models";
import { AppErrorDraftNotFound } from "../../models/error";
import store, { actions, selectors } from "../../store";
import { updateHasServiceReportDrafts } from "./service-report-draft";

/**
 * 下書きのサービスレポート明細を参照用のサービスレポート明細に保存する
 * @param key 下書きの識別子
 */
export function fetchDraftServiceReportDetail(key: string) {
  const state = store.getState();
  const draft = selectors.draft.serviceReportDetailsSelectors.selectById(
    state.draft.serviceReportDetails,
    key
  );
  if (!draft) {
    throw new AppErrorDraftNotFound(
      `couldn't find service report detail draft. (Key: ${key}）`
    );
  }
  const account = state.account.user;

  const workers: string[] = [];
  draft.workingTimes.forEach((wt) => {
    if (!!wt.workerName) workers.push(wt.workerName);
  });

  const convert: ServiceReportDetailDetail = {
    ...draft,
    id: draft.id || draft.key,
    isLatest: true,
    isTransmitted: false,
    status: SERVICE_REPORT_OFFLINE_STATUS,
    hasDraft: true,
    lastUpdatedUserId: account!.id,
    lastUpdatedUserName: account!.name,
    workers: workers.join(" / "),
    versions: [],
    checkers: [],
  };
  store.dispatch(actions.serviceReport.serviceReportDetailUpdate(convert));
}

/**
 * サービスレポートの明細情報の下書き登録処理
 *
 * @param data 登録内容
 */
export function postNewServiceReportDetailDraft(
  data: ServiceReportDetailDraft
) {
  const action = actions.draft.serviceReportDetailAdded(data);
  store.dispatch(action);
  updateHasServiceReportDrafts(data.fShipNo);
}

/**
 * サービスレポートの明細情報の下書き更新処理
 *
 * @param key 下書きの識別子
 * @param data 更新内容
 */
export function updateServiceReportDetailDraft(
  key: string,
  data: Omit<ServiceReportDetailDraft, "key">
) {
  const payload = {
    key,
    ...data,
  };
  const action = actions.draft.serviceReportDetailUpdated(payload);
  store.dispatch(action);
  updateHasServiceReportDrafts(data.fShipNo);
}

/**
 * サービスレポートの明細情報の下書き削除処理
 *
 * @param key 下書きの識別子
 */
export function deleteServiceReportDetailDraft(key: string, fShipNo: string) {
  store.dispatch(actions.draft.serviceReportDetailRemoved(key));
  updateHasServiceReportDrafts(fShipNo);
}

/**
 * サービスレポートの明細情報の下書きをサービスレポート明細ID を使用して取得する
 *
 * @param serviceReportId サービスレポートID
 * @param serviceReportDetailId サービスレポート明細ID
 */
export function getServiceReportHeaderDraftById(
  serviceReportId: string,
  serviceReportDetailId: number | string
) {
  const serviceReportDetail = selectors.draft.customSelectors.serviceReportDetailsByServiceReportIdDetailId(
    store.getState().draft,
    serviceReportId,
    serviceReportDetailId
  );
  return serviceReportDetail;
}
