import {
  AttachmentFile,
  Comment,
  Draft,
  isEditedDraft,
  Memo,
} from "../../models";
import store, { actions, selectors } from "../../store";
import {
  jsonToDraftComment,
  jsonToDraftMemo,
  memoToJson,
} from "../../store/serializers";
import { getAuthedWebapi } from "./custom-webapi";
import { equipmentMemoPageSize } from "../../models";
import * as equipmentDraft from "./model-draft";
import { MemoWithCategoryCreate, MentionUser } from "../../webapi/generated";
import * as modelDraft from "./model-draft";
import { isUnreadMemoMention, uploadAttachmentFile } from "./memo";
import { NewCommentData } from "../../components/MemoEditor/MemoDetail";
import { withLanguageHeader } from "../../webapi";

/**
 * 機種マスタの取得
 */
export async function fetchModels() {
  const webapi = await getAuthedWebapi();
  const { models } = await webapi.getModels();
  return models;
}

/**
 * @return モデル画像の URL を返す。モデル画像が存在しない場合は undefined を返す。
 */
export async function fetchModelImageUrl(
  modelId: string
): Promise<string | undefined> {
  const webapi = await getAuthedWebapi();

  try {
    const url = await webapi.getModelsEquipmentIdImageUrl({
      equipmentId: modelId,
    });
    return url;
  } catch (e) {
    if (e instanceof Response && e.status === 404) return undefined;
    throw e; // re-throw
  }
}

type PostNewEquipmentMemo = Omit<
  MemoWithCategoryCreate,
  "files" | "mentionedUsers"
> & {
  files: File[];
} & {
  mentionedUsers: MentionUser[];
};

type UpdateEquipmentMemo = Omit<PostNewEquipmentMemo, "files"> & {
  files: Array<File | AttachmentFile>;
};

export async function fetchModelUnits(modelId: string) {
  const webapi = await getAuthedWebapi();

  const { units } = await webapi.getModelsEquipmentIdUnits({
    equipmentId: modelId,
  });

  return units;
}

export async function fetchModelExplodedViews(modelId: string) {
  const webapi = await getAuthedWebapi();

  const { explodedViewGroups } = await webapi.getModelsEquipmentIdExplodedViews(
    {
      equipmentId: modelId,
    }
  );

  return explodedViewGroups;
}

export async function fetchModelExplodedViewImageUrl(
  modelId: string,
  fileId: number
) {
  const webapi = await getAuthedWebapi();

  const url = await webapi.getModelsEquipmentIdExplodedViewsFileIdImageUrl({
    equipmentId: modelId,
    fileId,
  });

  return url;
}

export async function fetchEquipmentMemos(
  shipId: string,
  equipmentId: string,
  language: string,
  page: number,
  categories?: number[]
) {
  const webapi = await getAuthedWebapi();
  const withLang = withLanguageHeader(webapi, language);

  const { memos, count } = await withLang.getModelsEquipmentIdMemos({
    fShipNo: shipId,
    equipmentId,
    page,
    limit: equipmentMemoPageSize,
    categories: categories?.length ? categories : undefined,
  });

  const models = memos.map(
    (x): Memo => {
      const comments = x.comments.map(
        (base): Comment => {
          if (base.isDeleted) return { ...base, isDeleted: true };
          const isUnread = isUnreadMemoMention(
            base.id,
            base.mentionedUsers!,
            base.updatedAt,
            undefined,
            equipmentId,
            undefined,
            x.id
          );
          return {
            ...base,
            isDeleted: false,
            isUnreadMention: isUnread,
            files: base.files!.map((f) => ({ ...f, available: true })),
          };
        }
      );
      const base = { ...x, comments };
      if (x.isDeleted) return { ...base, isDeleted: true };
      const isUnread = isUnreadMemoMention(
        x.id,
        x.mentionedUsers!,
        x.updatedAt,
        undefined,
        equipmentId
      );
      return {
        ...base,
        title: x.title!,
        detail: x.detail!,
        isDeleted: false,
        isUnreadMention: isUnread,
        files: x.files!.map((f) => ({ ...f, available: true })),
        categories: x.categories,
      };
    }
  );

  store.dispatch(
    actions.ship.equipmentMemosReceived({
      memos: models.map(memoToJson),
      count,
    })
  );
}

/**
 * メモ作成処理
 *
 * オンライン: API 実行
 * オフライン: 下書き作成
 *
 * @param shipId 船舶番号
 * @param equipmentId 機器ID
 * @param data 登録内容
 * @param key 下書き識別子
 */
export async function postNewEquipmentMemo(
  shipId: string,
  equipmentId: string,
  data: PostNewEquipmentMemo,
  key: Draft["key"] = ""
) {
  const { setting, draft } = store.getState();
  const draftMemo = selectors.draft.equipmentMemosSelectors.selectById(
    draft.equipmentMemos,
    key
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );
  if (fulfilledConnect) {
    const webapi = await getAuthedWebapi();

    // 添付ファイルを全てアップロードする
    const uploadedFiles = await Promise.all(
      data.files.map((file) =>
        uploadAttachmentFile(file, () => webapi.getMemosFileUploadUrl())
      )
    );

    // メモデータを作成する
    await webapi.postModelsEquipmentIdMemos({
      fShipNo: shipId,
      equipmentId,
      memoWithCategoryCreate: {
        ...data,

        files: uploadedFiles,
        mentionedUsers: data.mentionedUsers.map((user) => user.id),
      },
    });

    // API 実行による登録後、同一の下書きがあれば削除する
    if (draftMemo)
      modelDraft.deleteEquipmentDraftMemo(shipId, equipmentId, draftMemo.key);
  } else {
    if (draftMemo) {
      modelDraft.updateEquipmentDraftMemo(
        shipId,
        equipmentId,
        draftMemo.key,
        data
      );
    } else {
      modelDraft.postNewEquipmentDraftMemo(shipId, equipmentId, data);
    }
  }
}

/**
 * メモ更新処理
 *
 * オンライン: API 実行
 * オフライン: 下書き更新
 *
 * @param shipId 船舶番号
 * @param equipmentId 機器ID
 * @param memoId メモID
 * @param data 更新内容
 * @param key 下書き識別子
 */
export async function updateEquipmentMemo(
  shipId: string,
  equipmentId: string,
  memoId: Memo["id"],
  data: UpdateEquipmentMemo,
  key: Draft["key"] = ""
) {
  const { setting, ship, draft } = store.getState();
  const draftMemo = selectors.draft.equipmentMemosSelectors.selectById(
    draft.equipmentMemos,
    key
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );
  if (fulfilledConnect) {
    const webapi = await getAuthedWebapi();

    // 添付ファイルを全てアップロードする (新規のファイルのみ)
    const newFiles = data.files.filter(
      (x: any): x is File => x instanceof File
    );
    const uploadedFiles = await Promise.all(
      newFiles.map((file) =>
        uploadAttachmentFile(file, () => webapi.getMemosFileUploadUrl())
      )
    );

    // 以前の添付ファイルと合わせて、新しいファイルのリストを作成する
    const remainFiles: Array<{ id: number }> = data.files.filter(
      (x: any): x is { id: number } =>
        typeof x === "object" && x !== null && typeof x.id === "number"
    ) as any;
    const files = [...uploadedFiles, ...remainFiles];

    // 以前のメモデータを取得する
    const prevData = selectors.equipmentMemo.selectById(
      ship.equipmentMemos,
      memoId
    );
    if (!prevData) throw new Error("cannot find equipment memo to update");
    if (prevData.isDeleted)
      throw Error("cannot update memos that have already been deleted");

    // メモデータを更新する
    await webapi.putModelsEquipmentIdMemosMemoId({
      fShipNo: shipId,
      equipmentId,
      memoId,
      memoWithCategoryUpdate: {
        ...prevData,
        ...data,
        files,
        mentionedUsers: data.mentionedUsers.map((user) => user.id),
      },
    });

    // API 実行による登録後、同一の下書きがあれば削除する
    if (draftMemo)
      modelDraft.deleteEquipmentDraftMemo(shipId, equipmentId, draftMemo.key);
  } else {
    if (draftMemo) {
      modelDraft.updateEquipmentDraftMemo(
        shipId,
        equipmentId,
        draftMemo.key,
        data
      );
    } else {
      modelDraft.postNewEquipmentDraftMemo(shipId, equipmentId, {
        ...data,
        id: memoId,
      });
    }
  }
}

/**
 * メモ削除処理
 *
 * オンライン: API 実行
 * オフライン: 下書き削除
 *
 * @param shipId 船舶番号
 * @param equipmentId 機器ID
 * @param memoId メモID
 * @param key 下書き識別子
 */
export async function deleteEquipmentMemo(
  shipId: string,
  equipmentId: string,
  memoId: Memo["id"],
  key: Draft["key"] = ""
) {
  const { setting, draft } = store.getState();
  const draftMemo = selectors.draft.equipmentMemosSelectors.selectById(
    draft.equipmentMemos,
    key
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );
  if (fulfilledConnect) {
    const webapi = await getAuthedWebapi();
    const args = { fShipNo: shipId, equipmentId, memoId };

    if (draftMemo) {
      // 下書きが有る上に編集下書きの場合（新規作成の場合 API を実行しない）
      if (isEditedDraft(jsonToDraftMemo(draftMemo))) {
        await webapi.deleteModelsEquipmentIdMemosMemoId(args);
      }
    } else {
      await webapi.deleteModelsEquipmentIdMemosMemoId(args);
    }
  }

  // API 実行による登録後、同一の下書きがあれば削除する
  if (draftMemo)
    modelDraft.deleteEquipmentDraftMemo(shipId, equipmentId, draftMemo.key);
}

/**
 * コメント作成処理
 *
 * オンライン: API 実行
 * オフライン: 下書き作成
 *
 * @param shipId 船舶番号
 * @param equipmentId 機器ID
 * @param memoId メモID
 * @param comment 登録内容
 * @param key 下書き識別子
 */
export async function postNewEquipmentMemoComment(
  shipId: string,
  equipmentId: string,
  memoId: Memo["id"],
  comment: NewCommentData,
  key: Draft["key"] = ""
) {
  const { setting, draft } = store.getState();

  const draftComment = selectors.draft.equipmentCommentsSelectors.selectById(
    draft.equipmentComments,
    key
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );
  if (fulfilledConnect) {
    const webapi = await getAuthedWebapi();

    // 添付ファイルを全てアップロードする
    const files = await Promise.all(
      comment.files
        .filter((x: any): x is File => x instanceof File)
        .map((file) =>
          uploadAttachmentFile(file, () => webapi.getMemosFileUploadUrl())
        )
    );

    await webapi.postModelsEquipmentIdMemosMemoIdComments({
      fShipNo: shipId,
      equipmentId,
      memoId,
      commentCreate: {
        message: comment.message,
        files,
        mentionedUsers: comment.mentionedUsers.map((user) => user.id),
      },
    });

    // API 実行による登録後、同一の下書きがあれば削除する
    if (draftComment)
      modelDraft.deleteEquipmentDraftComment(
        shipId,
        equipmentId,
        draftComment.key
      );
  } else {
    if (draftComment) {
      modelDraft.updateEquipmentDraftComment(
        shipId,
        equipmentId,
        draftComment.key,
        comment
      );
    } else {
      modelDraft.postNewEquipmentDraftComment(
        shipId,
        equipmentId,
        memoId,
        comment
      );
    }
  }
}

/**
 * コメント更新処理
 *
 * オンライン: API 実行
 * オフライン: 下書き更新
 *
 * @param shipId 船舶番号
 * @param equipmentId 機器ID
 * @param memoId メモID
 * @param commentId コメントID
 * @param data 更新内容
 * @param key 下書き識別子
 */
export async function updateEquipmentMemoComment(
  shipId: string,
  equipmentId: string,
  memoId: Memo["id"],
  commentId: Comment["id"],
  data: NewCommentData,
  key: Draft["key"] = ""
) {
  const { setting, draft, ship } = store.getState();

  const draftComment = selectors.draft.equipmentCommentsSelectors.selectById(
    draft.equipmentComments,
    key
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );
  if (fulfilledConnect) {
    const webapi = await getAuthedWebapi();

    // 添付ファイルを全てアップロードする (新規のファイルのみ)
    const newFiles = data.files.filter(
      (x: any): x is File => x instanceof File
    );
    const uploadedFiles = await Promise.all(
      newFiles.map((file) =>
        uploadAttachmentFile(file, () => webapi.getMemosFileUploadUrl())
      )
    );

    // 以前の添付ファイルと合わせて、新しいファイルのリストを作成する
    const remainFiles: Array<{ id: number }> = data.files.filter(
      (x: any): x is { id: number } =>
        typeof x === "object" && x !== null && typeof x.id === "number"
    ) as any;
    const files = [...uploadedFiles, ...remainFiles];

    // 以前のコメントデータを取得する
    const prevData = selectors.equipmentMemo
      .selectById(ship.equipmentMemos, memoId)
      ?.comments.find((c) => c.id === commentId);
    if (!prevData)
      throw new Error("cannot find equipment memo comment to update");
    if (prevData.isDeleted)
      throw Error("cannot update comments that have already been deleted");

    // メモデータを更新する
    await webapi.putModelsEquipmentIdMemosMemoIdCommentsCommentId({
      fShipNo: shipId,
      equipmentId,
      memoId,
      commentId,
      commentUpdate: {
        ...prevData,
        ...data,
        files,
        mentionedUsers: data.mentionedUsers.map((user) => user.id),
      },
    });

    // API 実行による登録後、同一の下書きがあれば削除する
    if (draftComment)
      modelDraft.deleteEquipmentDraftComment(
        shipId,
        equipmentId,
        draftComment.key
      );
  } else {
    if (draftComment) {
      modelDraft.updateEquipmentDraftComment(
        shipId,
        equipmentId,
        draftComment.key,
        data
      );
    } else {
      modelDraft.postNewEquipmentDraftComment(shipId, equipmentId, memoId, {
        ...data,
        id: commentId,
      });
    }
  }
}

/**
 * コメント削除処理
 *
 * オンライン: API 実行
 * オフライン: 下書き削除
 *
 * @param shipId 船舶番号
 * @param equipmentId 機器ID
 * @param memoId メモID
 * @param commentId コメントID
 * @param key 下書き識別子
 */
export async function deleteEquipmentMemoComment(
  shipId: string,
  equipmentId: string,
  memoId: Memo["id"],
  commentId: Comment["id"],
  key: Draft["key"] = ""
) {
  const { setting, draft } = store.getState();
  const draftComment = selectors.draft.equipmentCommentsSelectors.selectById(
    draft.equipmentComments,
    key
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );
  if (fulfilledConnect) {
    const webapi = await getAuthedWebapi();
    const args = { fShipNo: shipId, equipmentId, memoId, commentId };

    if (draftComment) {
      // 下書きが有る上に編集下書きの場合（新規作成の場合 API を実行しない）
      if (isEditedDraft(jsonToDraftComment(draftComment))) {
        await webapi.deleteModelsEquipmentIdMemosMemoIdCommentsCommentId(args);
      }
    } else {
      await webapi.deleteModelsEquipmentIdMemosMemoIdCommentsCommentId(args);
    }
  }

  // API 実行による登録後、同一の下書きがあれば削除する
  if (draftComment)
    modelDraft.deleteEquipmentDraftComment(
      shipId,
      equipmentId,
      draftComment.key
    );
}

/**
 * re_export
 */
export const pullDraft = equipmentDraft.pullDraft;
