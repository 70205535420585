import classNames from "classnames";
import { ChangeEvent, FC, useCallback, useReducer } from "react";
import { useTranslation } from "react-i18next";
import type { AttachmentFile } from "../../models";
import styles from "./AttachmentButton.module.css";
import { ReactComponent as Clip } from "./ic_60_clip.svg";

type Props = {
  readonly?: boolean;
  disabled?: boolean;
  onAttach?: (attachments: File[]) => void;
};

/**
 * このコンポーネントの責務は添付ファイルを追加するボタンを提供すること
 *
 * AttachmentFiles コンポーネントと組み合わせて使用することを想定している
 */
const AttachmentButton: FC<Props> = ({ readonly, disabled, onAttach }) => {
  const [inputDomKey, onChange] = useOnChange(onAttach);
  return (
    <label
      className={styles.host}
      role="button"
      aria-label="attach file"
      tabIndex={0}
    >
      <input
        key={inputDomKey}
        name="attachments"
        type="file"
        multiple
        disabled={readonly || disabled}
        className={styles.hidden}
        onChange={onChange}
      />
      <Clip
        className={classNames(styles.clipIcon, disabled && styles.disabled)}
      />
    </label>
  );
};
export default AttachmentButton;

function useOnChange(onAttach: Props["onAttach"]) {
  // inputDomKey は onChange が呼ばれてファイルが追加されるたびに
  // <input> 要素の DOM を破棄＆再生成するために用いる。
  // これにより、A.png を選択したのち、A.png を再び選択すると DOM 状態が変わらないため
  // アプリケーションにファイルが追加されない不具合が発生することを防ぐ。
  //
  // <input type="file"> 要素は DOM に添付ファイルの **状態** を持つ。
  // このアプリケーションでは個別のファイル削除などを実現するために、DOM の添付ファイルの
  // 状態と React の状態を同期させているのではなく、イベント発生時に追加されたファイルを
  // React 状態に追加するという方法で管理している。
  // そのため DOM の添付ファイルの状態に変化がなければ change イベントが発生しないので
  // React 状態にも反映されない。A.png を選択したのち、A.png を再び選択する操作は
  // DOM の添付ファイルの状態に変化が起こらないのでこの問題が発生する。
  //
  // 上記の問題を防ぐため、添付ファイルが選択されるたびに (React 状態に反映したのち)
  // <input> の DOM を破棄＆再生成する。つまり常に <input> の添付ファイルは空状態にする。
  // これを実現するため添付ファイルが選択されるたびに React の key を変更する。
  const [inputDomKey, dispatch] = useReducer((s: number) => s + 1, 0);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(event.currentTarget.files || []);
      onAttach?.(files);
      dispatch();
    },
    [onAttach]
  );

  return [inputDomKey, onChange] as const;
}

const attachmentMaxSize = 5 * 1024 ** 3; // 5 GB
const attachmentMaxLength = 100;

type Attachments = Array<AttachmentFile | File>;
export function useOnAttach(
  setAttachments: (updater: (attachments: Attachments) => Attachments) => void
) {
  const { t } = useTranslation();
  const max = maxSize();
  return useCallback(
    (attachments: File[]) => {
      setAttachments((s) => {
        if (attachments.some((a) => a.size > max)) {
          alert(t("The file size exceeds 5GB."));
          return s;
        }

        if (s.length + attachments.length > attachmentMaxLength) {
          alert(t("The number of attached files exceeds the maximum."));
          return s;
        }

        return [...s, ...attachments];
      });
    },
    [max, setAttachments, t]
  );
}

function maxSize(): number {
  if (!process.env.REACT_APP_ATTACHMENT_MAX_SIZE) {
    return attachmentMaxSize;
  }
  const size = parseInt(process.env.REACT_APP_ATTACHMENT_MAX_SIZE, 10);
  if (Number.isNaN(size)) {
    return attachmentMaxSize;
  }
  return size;
}
