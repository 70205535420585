import {
  ServiceReportSign,
  ServiceReport,
  ServiceReportHeaderDetail,
  ServiceReportHeaderCreate,
  serviceReportHeaderStatus,
  ServiceReportHeaderUpdate,
  ServiceReportDetailDraft,
  serviceReportDetailStatus,
  ServiceReportDetailStatus,
  ServiceReportWorkerUpdate,
  ServiceReportWorker,
  serviceReportOrderType,
  serviceReportOrderTypeName,
  replaceTransmitStatus,
  serviceReportTransmitMessages,
  ServiceReportDetail,
  ServiceReportTransmitParams,
} from "../../models";
import { withLanguageHeader } from "../../webapi";
import {
  GetServiceReportsRequest,
  GetShipsFShipNoModelsSortEnum,
} from "../../webapi/generated";
import { getAuthedWebapi, isOnline } from "./custom-webapi";
import { serviceReportDraft } from "../../usecases";
import {
  AppErrorNotLogin,
  AppErrorSearchLimitExceeded,
  AppOfflineAttention,
} from "../../models/error";
import { ensureContinuousOperation, getAccessToken } from "./auth";
import store, { actions, selectors } from "../../store";
import { updateHasServiceReportDrafts } from "./service-report-draft";
import { serviceReportToJson } from "../../store/serializers/serviceReport";
import { throwSearchErrorByLength } from "./search";
import { groupBy } from "./array";

/**
 * サービスレポート一覧取得
 *
 * @param requestParameters 検索条件
 * @param hasLimit 取得数制限の有無
 */
export async function fetchServiceReports(
  requestParameters: GetServiceReportsRequest,
  hasLimit = false
): Promise<ServiceReport[]> {
  const webapi = await getAuthedWebapi();
  const response = await webapi.getServiceReports(requestParameters);

  // 主にサービスレポート検索画面で用いる
  if (hasLimit) {
    if (response.searchResultLimitExceeded) {
      throw new AppErrorSearchLimitExceeded(
        "search process was interrupted because search limit was exceeded"
      );
    }
    throwSearchErrorByLength(response.serviceReports.length);
  }

  const convertData: ServiceReport[] = response.serviceReports.map((r) => {
    const draftServiceReport = selectors.draft.customSelectors.serviceReportHeaderById(
      store.getState().draft,
      r.id
    );

    // サービスレポートが 請求済み かつ 下書きが存在する場合、下書きを削除
    const isBilled = r.status === serviceReportHeaderStatus.BILLED;
    if (isBilled && draftServiceReport) {
      actions.draft.serviceReportHeaderRemoved(draftServiceReport.key);
    }

    const serviceReport: ServiceReport = {
      ...r,
      status: replaceTransmitStatus(r.status),
      serviceReportDraftKey: isBilled ? undefined : draftServiceReport?.key,
      hasDraft: serviceReportDraft.hasDraft(r.fShipNo, r.id, isBilled),
    };
    return serviceReport;
  });

  return convertData;
}

/**
 * サービスレポートの登録処理
 *
 * @param data 登録内容
 */
export async function postNewServiceReport(data: ServiceReportHeaderCreate) {
  const webapi = await getAuthedWebapi();
  return webapi.postServiceReports({ serviceReportHeaderCreate: data });
}

/**
 * サービスレポートの更新処理
 *
 * @param serviceReportId サービスレポートID
 * @param data 更新内容
 */
export async function updateServiceReport(
  serviceReportId: number,
  data: ServiceReportHeaderUpdate
) {
  const webapi = await getAuthedWebapi();
  return webapi.putServiceReportsServiceReportId({
    serviceReportId,
    serviceReportHeaderUpdate: data,
  });
}

/**
 * サービスレポートの削除処理
 *
 * @param serviceReportId サービスレポートID
 */
export async function deleteServiceReport(serviceReportId: number) {
  const webapi = await getAuthedWebapi();
  return webapi.deleteServiceReportsServiceReportId({
    serviceReportId,
  });
}

/**
 * サービスレポートの署名処理
 *
 * @param serviceReportId サービスレポートID
 * @param data 署名内容
 */
export async function signServiceReport(
  serviceReportId: number,
  data: ServiceReportSign
) {
  const webapi = await getAuthedWebapi();
  await webapi.postServiceReportsServiceReportIdSign({
    serviceReportId,
    serviceReportSign: data,
  });
}

/**
 * サービスレポートのダウンロード処理
 *
 * @param serviceReportId サービスレポートID
 * @param language 設定言語
 */
export async function downloadServiceReport(
  serviceReportId: number,
  language: string
) {
  const headers = await createHeaders(language);
  const pathname = `/service-reports/${encodeURIComponent(
    serviceReportId
  )}/download`;
  const url = `${process.env.REACT_APP_WEBAPI_BASE_PATH}${pathname}`;
  return await fetch(url, { method: "GET", headers });
}

/**
 * サービスレポート工事報告書作成
 *
 * @param serviceReportId サービスレポートID
 */
export async function postServiceReportFile(serviceReportId: number) {
  const webapi = await getAuthedWebapi();
  return await webapi.postServiceReportsServiceReportIdFile({
    serviceReportId,
  });
}

/**
 * 明細のダウンロード処理
 *
 * @param serviceReportId サービスレポートID
 * @param serviceReportDetailId サービスレポートID
 * @param language 設定言語
 */
export async function downloadServiceReportDetail(
  serviceReportId: number,
  serviceReportDetailId: number,
  language: string
) {
  const headers = await createHeaders(language);
  const pathname = `/service-reports/${encodeURIComponent(
    serviceReportId
  )}/details/${encodeURIComponent(serviceReportDetailId)}/download`;
  const url = `${process.env.REACT_APP_WEBAPI_BASE_PATH}${pathname}`;
  return await fetch(url, { method: "GET", headers });
}

/**
 * サービスレポート明細の工事報告書作成
 *
 * @param serviceReportId サービスレポートID
 */
export async function postServiceReportDetailFile(
  serviceReportId: number,
  serviceReportDetailId: number
) {
  const webapi = await getAuthedWebapi();
  return await webapi.postServiceReportsServiceReportIdDetailsServiceReportDetailIdFile(
    {
      serviceReportId,
      serviceReportDetailId,
    }
  );
}

/**
 * 明細の一括ダウンロード処理
 *
 * @param serviceReportId サービスレポートID
 * @param serviceReportDetailIds サービスレポートIDの配列
 * @param language 設定言語
 */
export async function downloadServiceReportDetailZip(
  serviceReportId: number,
  serviceReportDetailIds: number[],
  language: string
) {
  const headers = await createHeaders(language);
  const params = serviceReportDetailIds.map(
    (d) => `detailIds=${encodeURIComponent(d)}`
  );
  const pathname = `/service-reports/${encodeURIComponent(
    serviceReportId
  )}/details/download?${params.join("&")}`;

  const url = `${process.env.REACT_APP_WEBAPI_BASE_PATH}${pathname}`;
  return await fetch(url, {
    method: "GET",
    headers,
  });
}

/**
 * サービスレポート検索画面の条件の取得
 */
export async function fetchServiceReportsConditions() {
  const webapi = await getAuthedWebapi();

  const [modelTypes, serviceKinds] = await Promise.all([
    // 機種一覧
    (async () => {
      const { models } = await webapi.getModels();
      const types = models.map(({ modelType }) => modelType).filter((x) => x);
      const uniqued = Array.from(new Set(types));
      return uniqued;
    })(),
    // 伝票種別マスタ
    (async () => {
      const { serviceKinds } = await webapi.getServiceKinds();
      return serviceKinds.map((sk) => {
        return {
          value: sk.code,
          text: sk.name,
        };
      });
    })(),
  ]);

  return { modelTypes, serviceKinds };
}

/**
 * 編集下書きの存在確認と変換処理
 *
 * * 編集対象のレポートが存在するかを確認後、結果に応じて処理する
 */
export async function checkServiceReportDraftExists() {
  const state = store.getState();
  const fullfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    state.setting
  );
  const {
    serviceReports,
    signatures,
    memos,
    comments,
    details,
  } = selectors.draft.customSelectors.serviceReportDraftIds(state.draft);
  const bookmarks = selectors.bookmark.shipsSelectors.selectAll(
    state.bookmark.ships
  );

  // 通信接続が不可な場合、後続処理を行わない
  if (!fullfilledConnect) return;

  const reportIds = Array.from(
    new Set([
      ...serviceReports.map((s) => s.id),
      ...signatures.map((s) => s.id),
      ...memos.map((m) => m.id),
      ...comments.map((c) => c.id),
      ...details.map((d) => d.id),
    ])
  ).filter((s): s is number => typeof s === "number");

  if (reportIds.length === 0) return;

  const response = await checkServiceReportExists(reportIds);
  const deleteTargetStatuses = [serviceReportHeaderStatus.BILLED];

  reportIds.forEach((id) => {
    const reportDraft = serviceReports.find((sr) => sr.id === id);
    const signatureDraft = signatures.find((s) => s.id === id);
    const detailDraft = details.find((d) => d.id === id);

    const report = response.find((sr) => sr.id === id);
    if (!report && reportDraft) {
      // 編集対象のレポートヘッダーが存在しない場合、新規作成の下書きに変換する
      store.dispatch(
        actions.draft.serviceReportHeaderEdit2CreateConverted(reportDraft.key)
      );
    }
    if (!report && signatureDraft) {
      // 下書きの署名記入先のレポートが存在しない場合、削除する
      store.dispatch(
        actions.draft.serviceReportSignatureRemoved(signatureDraft.key)
      );
    }
    if (!report && detailDraft) {
      // 下書きの明細登録先のレポートが存在しない場合、削除する
      store.dispatch(actions.draft.serviceReportDetailRemoved(detailDraft.key));
    }
    if (!report) {
      // レポートが存在しない且つ紐づくメモやコメントの下書きが存在する場合、削除する
      const deleteMemos = memos.filter((m) => m.id === id);
      deleteMemos.forEach((m) => {
        store.dispatch(actions.draft.serviceReportMemoRemoved(m.key));
      });
      const deleteComments = comments.filter((c) => c.id === id);
      deleteComments.forEach((c) =>
        store.dispatch(actions.draft.serviceReportCommentRemoved(c.key))
      );
    }
    if (report && reportDraft) {
      // 編集対象のレポートのステータスが請求済みの場合、削除する
      if (!deleteTargetStatuses.includes(report.status)) return;
      store.dispatch(actions.draft.serviceReportHeaderRemoved(reportDraft.key));
    }
    if (report && signatureDraft) {
      // 下書きの署名記入先のレポートのステータスが請求済みの場合、削除する
      if (!deleteTargetStatuses.includes(report.status)) return;
      store.dispatch(
        actions.draft.serviceReportSignatureRemoved(signatureDraft.key)
      );
    }
    if (report && detailDraft) {
      // 下書きの明細登録先のレポートのステータスが請求済みの場合、削除する
      if (!deleteTargetStatuses.includes(report.status)) return;
      store.dispatch(actions.draft.serviceReportDetailRemoved(detailDraft.key));
    }
  });

  await checkServiceReportDetailDraftExists();

  // ブックマークに登録済みの船舶の下書き所有判定を更新する
  bookmarks.forEach((ship) => updateHasServiceReportDrafts(ship.fShipNo));
}

/**
 * サービスレポートの存在確認
 *
 * @param ids サービスレポートID一覧
 */
async function checkServiceReportExists(ids: number[]) {
  const webapi = await getAuthedWebapi();
  const { serviceReports } = await webapi.getServiceReportsExists({ ids });
  return serviceReports;
}

/**
 * サービスレポート明細の編集下書きの存在確認と変換処理
 *
 * * 編集対象のレポートが存在するかを確認後、結果に応じて処理する
 */
export async function checkServiceReportDetailDraftExists() {
  const state = store.getState();
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    state.setting
  );
  const details = selectors.draft.customSelectors.serviceReportDetailEditDraft(
    state.draft
  );
  // 通信接続が不可な場合、後続処理を行わない
  if (!fulfilledConnect) return;
  if (details.length === 0) return;

  const groupByServiceReportId = groupBy<ServiceReportDetailDraft>(
    details,
    "serviceReportId"
  );

  const serviceReportIds = Object.keys(groupByServiceReportId);
  await Promise.all(
    serviceReportIds.map(async (serviceReportId) => {
      const draftDetails = groupByServiceReportId[serviceReportId];
      const response = await checkServiceReportDetailExists(
        Number(serviceReportId),
        draftDetails.map((g) => Number(g.id))
      );

      draftDetails.forEach((dd) => {
        const find = response.find((r) => r.id === Number(dd.id));
        const isDeleteStatus =
          find && find.status === serviceReportDetailStatus.CHECK_COMPLETED;
        if (!find) {
          store.dispatch(
            actions.draft.serviceReportDetailEdit2CreateConverted(dd.key)
          );
        }
        if (isDeleteStatus) {
          store.dispatch(actions.draft.serviceReportDetailRemoved(dd.key));
        }
      });
    })
  );
}

/**
 * サービスレポート明細の存在確認
 *
 * @param serviceReportId サービスレポートID
 * @param ids 明細ID一覧
 */
async function checkServiceReportDetailExists(
  serviceReportId: number,
  ids: number[]
): Promise<{ id: number; status: ServiceReportDetailStatus }[]> {
  const webapi = await getAuthedWebapi();
  const {
    serviceReportDetails,
  } = await webapi.getServiceReportsServiceReportIdDetailsExists({
    serviceReportId: serviceReportId,
    ids: ids,
  });
  return serviceReportDetails;
}

/**
 * ファイル名の取得処理
 *
 * @param contentDisposition
 */
export function getFileName(contentDisposition: string) {
  const filenameRegex = /filename[^;=\n]=((['"]).*?\2|[^;\n]*)/;
  /**
   * matches[1]: {encode}''{filename}
   */
  const matches = filenameRegex.exec(contentDisposition);

  if (matches && matches[1]) {
    const rawFilename = matches[1].replace(/^(.*?)''/, "").replace(/\+/g, " ");
    return decodeURI(rawFilename);
  } else {
    return "untitled";
  }
}

/**
 * APIからサービスレポートヘッダを取得し、Storeに保存する
 *
 * @param fShipNo 船舶番号
 * @param serviceReportId サービスレポートID
 */
export async function fetchStoreServiceReport(
  fShipNo: string,
  serviceReportId: number
) {
  const webapi = await getAuthedWebapi();
  const response = await webapi.getServiceReportsServiceReportId({
    serviceReportId,
  });

  const draft = selectors.draft.customSelectors.serviceReportHeaderById(
    store.getState().draft,
    serviceReportId
  );

  // 下書きが存在する かつ （サービスレポートがデータ連携済み or 請求済み または 連携済みフラグがtrue）の場合、下書きを削除
  const includeSubmitCompletedOrBilled = [
    serviceReportHeaderStatus.SUBMIT_COMPLETED,
    serviceReportHeaderStatus.BILLED,
  ].includes(response.status);
  if (draft && (includeSubmitCompletedOrBilled || response.isTransmitted)) {
    store.dispatch(actions.draft.serviceReportHeaderRemoved(draft?.key));
    updateHasServiceReportDrafts(fShipNo);
  }

  const convert: ServiceReportHeaderDetail = {
    ...response,
    id: String(serviceReportId),
    status: replaceTransmitStatus(response.status),
    hasDraft:
      includeSubmitCompletedOrBilled || response.isTransmitted
        ? false
        : !!draft,
    models: response.models?.join(" / "),
  };

  store.dispatch(
    actions.serviceReport.serviceReportUpdate(serviceReportToJson(convert))
  );
}

/**
 * APIからサービスレポート明細一覧を取得し、Storeに保存する
 *
 * @param serviceReportId サービスレポートID
 */
export async function fetchStoreServiceReportDetails(serviceReportId: number) {
  const webapi = await getAuthedWebapi();
  const response = await webapi.getServiceReportsServiceReportIdDetails({
    serviceReportId,
  });

  store.dispatch(
    actions.serviceReport.serviceReportDetailsUpdate(
      response.serviceReportDetails
    )
  );
}

/**
 * APIから明細跨ぎの使用部品を取得し、Storeに保存する
 *
 * @param serviceReportId サービスレポートID
 */
export async function fetchStoreUsedParts(serviceReportId: number) {
  const webapi = await getAuthedWebapi();
  const { usedParts } = await webapi.getServiceReportsServiceReportIdUsedParts({
    serviceReportId,
  });

  const convert = usedParts.map((u) => {
    return {
      ...u,
      serviceReportId: String(serviceReportId),
    };
  });

  store.dispatch(actions.serviceReport.usedPartsUpdate(convert));
}

/**
 * APIから明細跨ぎの作業時間を取得し、Storeに保存する
 *
 * @param serviceReportId サービスレポートID
 */
export async function fetchStoreWorkingTimes(serviceReportId: number) {
  const webapi = await getAuthedWebapi();
  const {
    workingTimes,
  } = await webapi.getServiceReportsServiceReportIdWorkingTimes({
    serviceReportId,
  });
  const convert = workingTimes.map((w) => {
    return {
      ...w,
      serviceReportId: String(serviceReportId),
    };
  });
  store.dispatch(actions.serviceReport.workingTimesUpdate(convert));
}

/**
 * APIからEquipment一覧を取得し、Storeに保存する
 *
 * @param fShipNo 船舶番号
 * @param language 言語コード
 */
export async function fetchEquipments(fShipNo: string, language: string) {
  const webapi = await getAuthedWebapi();
  const withLangWebApi = withLanguageHeader(webapi, language);
  const { models } = await withLangWebApi.getShipsFShipNoModels({
    fShipNo: fShipNo,
    sort: GetShipsFShipNoModelsSortEnum.ModelTypeDesc,
  });

  store.dispatch(actions.serviceReport.equipmentsReceived(models));
}

/**
 * APIからワークオーダータスク一覧を取得し、Storeに保存する
 *
 * @param workOrderNo ワークオーダーNo
 * @param serviceReportId 言語コード
 */
export async function fetchWorkOrderTasks(
  workOrderNo: string,
  language: string
) {
  const webapi = await getAuthedWebapi();
  const withLangWebApi = withLanguageHeader(webapi, language);
  const { tasks } = await withLangWebApi.getWorkOrdersWorkOrderNoTasks({
    workOrderNo,
  });

  store.dispatch(actions.serviceReport.workOrderTasksReceived(tasks));
}

/**
 * サービスレポート 外部連携APIを実行処理
 *
 * 実行後サービスレポートヘッダ情報を更新
 * @param fShipNo 船舶番号
 * @param serviceReportId サービスレポートID
 */
export async function transmitSMSNext(
  fShipNo: string,
  serviceReportId: number
) {
  const webapi = await getAuthedWebapi();
  await webapi.postServiceReportsServiceReportIdTransmit({
    serviceReportId,
  });

  // 外部連携APIを実行後にサービスレポートヘッダの情報を更新する
  await fetchStoreServiceReport(fShipNo, serviceReportId);
}

/**
 * 作業種別マスタの取得
 */
export async function fetchServiceTypes() {
  const webapi = await getAuthedWebapi();
  const { serviceTypes } = await webapi.getServiceTypes();
  return serviceTypes;
}

/**
 * サービスレポート作業者の取得処理
 *
 * @param serviceReportId サービスレポートID
 */
export async function fetchServiceReportWorkers(serviceReportId: number) {
  const webapi = await getAuthedWebapi();
  const { workers } = await webapi.getServiceReportsServiceReportIdWorkers({
    serviceReportId,
  });

  const action = actions.serviceReport.workersReceived(workers);
  store.dispatch(action);
}

/**
 * サービスレポート作業者の更新処理
 *
 * @param serviceReportId サービスレポートID
 * @param data 更新内容
 */
export async function postServiceReportWorkers(
  serviceReportId: number,
  data: ServiceReportWorker[]
) {
  const workers = data.map((d): ServiceReportWorkerUpdate["workers"][0] => ({
    // formから受け取る型がStringになってしまうためNumber型に変換している
    id: Number(d.id),
    userId: Number(d.userId),
    name: d.name,
  }));

  const webapi = await getAuthedWebapi();
  return webapi.postServiceReportsServiceReportIdWorkers({
    serviceReportId,
    serviceReportWorkerUpdate: { workers },
  });
}

/**
 * オーダー種別の取得
 */
export function getOrderTypes() {
  const orderTypes = Object.keys(serviceReportOrderType).map((key) => {
    const orderTypeKey = key as keyof typeof serviceReportOrderType;
    return {
      value: String(serviceReportOrderType[orderTypeKey]),
      text: serviceReportOrderTypeName[serviceReportOrderType[orderTypeKey]],
    };
  });
  return orderTypes;
}

/**
 * データ連携に関する各項目を取得する
 *
 * @param connection 通信接続状態
 * @param serviceReportHeader サービスレポートのヘッダ情報
 * @param serviceReportDetails サービスレポートの明細情報一覧
 * @returns 表示可否・活性可否・データ連携実行に必要な内容
 */
export function getTransmitAttribute(
  connection: boolean,
  serviceReportHeader: ServiceReportTransmitParams | null,
  serviceReportDetails: Pick<ServiceReportDetail, "status">[] = []
) {
  const attribute = {
    visible: false,
    enable: false,
    messages: [] as string[],
  };
  if (!serviceReportHeader) return attribute;

  // データ連携ボタンの表示可否
  attribute.visible =
    connection &&
    (serviceReportHeader?.status === serviceReportHeaderStatus.IN_PROGRESS ||
      serviceReportHeader?.status ===
        serviceReportHeaderStatus.TO_BE_SUBMITTED);

  // データ連携ボタンの活性可否
  attribute.enable =
    serviceReportHeader?.status === serviceReportHeaderStatus.TO_BE_SUBMITTED;

  // データ連携をするために必要な内容
  attribute.messages = getTransmitMessages(
    serviceReportHeader,
    serviceReportDetails
  );

  return attribute;
}

/**
 * データ連携をするために必要な内容（文言）を取得する
 *
 * @param serviceReport サービスレポート
 * @param details サービスレポートに紐づく明細一覧
 * @returns データ連携に欠かせない文言
 */
function getTransmitMessages(
  serviceReport: ServiceReportTransmitParams,
  details: Pick<ServiceReportDetail, "status">[]
) {
  const messages = [];

  // 明細が 1 件も登録されていない、または全ての明細の審査が完了していない場合
  const allApproved = details.every(
    (d) => d.status === serviceReportDetailStatus.CHECK_COMPLETED
  );
  if (details.length === 0 || !allApproved) {
    messages.push(serviceReportTransmitMessages.APPROVE_DETAILS);
  }

  // お客様署名または署名がない理由が登録されていない場合
  if (!serviceReport.customerSignedAt) {
    messages.push(serviceReportTransmitMessages.CUSTOMER_SIGNATURE);
  }

  // 作業完了日が登録されていない場合
  if (!serviceReport.completionDate) {
    messages.push(serviceReportTransmitMessages.COMPLETION_DATE);
  }

  // ワークオーダーが登録されていない場合
  if (serviceReport.orderType !== serviceReportOrderType.WORK_ORDER) {
    messages.push(serviceReportTransmitMessages.WORK_ORDER);
  }

  return messages;
}

/**
 * リクエストヘッダー作成用の共通関数
 * webapiを使わずにAPI通信を実行する際に用いる
 *
 * @param language 設定言語
 */
async function createHeaders(language: string) {
  if (!isOnline()) {
    throw new AppOfflineAttention();
  }
  await ensureContinuousOperation();
  let token: string;
  try {
    token = await getAccessToken();
  } catch {
    throw new AppErrorNotLogin("Not Logged in");
  }
  const headers = new Headers();
  headers.set("authorization", token);
  headers.set("accept-language", language);
  headers.set(
    "x-timezone-iana",
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  return headers;
}
