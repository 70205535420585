import {
  SERVICE_REPORT_OFFLINE_STATUS,
  ServiceReportHeaderDetail,
  ServiceReportHeaderDraft,
  ServiceReportHeaderDraftCreate,
} from "../../models";
import { AppErrorDraftNotFound } from "../../models/error";
import store, { actions, selectors } from "../../store";
import {
  serviceReportHeaderDraftToJson,
  serviceReportToJson,
} from "../../store/serializers/serviceReport";
import { updateHasServiceReportDrafts } from "./service-report-draft";

/**
 * サービスレポートのヘッダ情報の下書き登録処理
 *
 * @param data 登録内容
 */
export function postNewServiceReportHeaderDraft(
  data: ServiceReportHeaderDraftCreate
) {
  const payload: ServiceReportHeaderDraft = {
    ...data,
    isTransmitted: false,
    hasDraft: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  };
  const action = actions.draft.serviceReportHeaderAdded(
    serviceReportHeaderDraftToJson(payload)
  );
  store.dispatch(action);
  updateHasServiceReportDrafts(data.fShipNo);
}

/**
 * サービスレポートのヘッダ情報の下書き更新処理
 *
 * @param key 下書きの識別子
 * @param data 更新内容
 */
export function updateServiceReportHeaderDraft(
  key: string,
  data: Omit<ServiceReportHeaderDraftCreate, "key">
) {
  const payload: ServiceReportHeaderDraft = {
    ...data,
    key,
    isTransmitted: false,
    hasDraft: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  };
  const action = actions.draft.serviceReportHeaderUpdated(
    serviceReportHeaderDraftToJson(payload)
  );
  store.dispatch(action);
  updateHasServiceReportDrafts(data.fShipNo);
}

/**
 * サービスレポートのヘッダ情報の下書き削除処理
 *
 * @param key 下書きの識別子
 * @param fShipNo 船舶番号
 */
export function deleteServiceReportHeaderDraft(key: string, fShipNo: string) {
  // ヘッダ情報の下書きを削除
  store.dispatch(actions.draft.serviceReportHeaderRemoved(key));
  updateHasServiceReportDrafts(fShipNo);
}

/**
 * サービスレポートのヘッダ情報登録後に紐づく下書きにサービスレポートID を付与する
 *
 * @param key ヘッダ情報の下書きの識別子
 * @param serviceReportId サービスレポートID
 */
export function attachServiceReportId(key: string, serviceReportId: number) {
  // ヘッダ情報の下書きに紐づく明細の下書きを取得
  const details = selectors.draft.customSelectors.serviceReportDetailsByServiceReportId(
    store.getState().draft,
    key
  );

  // 上記で取得した明細の下書きに設定されたサービスレポートID を更新
  const detailActions = details.map((detail) =>
    actions.draft.serviceReportDetailUpdated({
      ...detail,
      serviceReportId: String(serviceReportId),
    })
  );
  detailActions.forEach(store.dispatch);

  // ヘッダ情報の下書きに紐づく署名の下書きを取得
  const signature = selectors.draft.serviceReportSignaturesSelectors.selectById(
    store.getState().draft.serviceReportSignatures,
    key
  );

  // 上記で取得した署名の下書きに設定されたサービスレポートID を更新
  if (signature) {
    store.dispatch(
      actions.draft.serviceReportSignatureUpdated({
        key,
        data: { ...signature, id: serviceReportId },
      })
    );
  }
}

/**
 * サービスレポートのヘッダ情報の下書きをサービスレポートID を使用して取得する
 *
 * @param id サービスレポートID
 */
export function getServiceReportHeaderDraftById(id: number | string) {
  const serviceReport = selectors.draft.customSelectors.serviceReportHeaderById(
    store.getState().draft,
    id
  );
  return serviceReport;
}

/**
 * 下書きのサービスレポートヘッダを参照用のサービスレポートヘッダに保存する
 * @param serviceReportId サービスレポートID
 */
export function fetchDraftServiceReport(serviceReportId: string) {
  const draft = selectors.draft.customSelectors.serviceReportHeaderById(
    store.getState().draft,
    serviceReportId
  );
  if (!draft) {
    throw new AppErrorDraftNotFound(
      `couldn't find service report draft. (Key: ${serviceReportId}）`
    );
  }

  const convert: ServiceReportHeaderDetail = {
    ...draft,
    id: draft.key,
    models: draft.models,
    status: SERVICE_REPORT_OFFLINE_STATUS,
    hasDraft: true,
  };
  store.dispatch(
    actions.serviceReport.serviceReportUpdate(serviceReportToJson(convert))
  );
}
