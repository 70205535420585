import type { UpdateMemoData } from "../../components/DrawingTool";
import type { NewCommentData } from "../../components/MemoEditor/MemoDetail";
import { Comment, Draft, Memo } from "../../models";
import {
  isEditedDraft,
  deserializeDrawingInstances,
  diagramMemoPageSize,
  serializeDrawingInstances,
} from "../../models";
import store, { actions, selectors } from "../../store";
import {
  jsonToDraftComment,
  jsonToDraftMemo,
  memoToJson,
} from "../../store/serializers";
import { MemoWithPathCreate, MentionUser } from "../../webapi/generated";
import { getAuthedWebapi } from "./custom-webapi";
import * as diagramDraft from "./diagram-draft";
import { isUnreadMemoMention, uploadAttachmentFile } from "./memo";

export async function fetchDiagramImageUrl(shipId: string, diagramId: string) {
  const webapi = await getAuthedWebapi();

  const url = await webapi.getShipsFShipNoDiagramsDiagramIdImageUrl({
    fShipNo: shipId,
    diagramId,
  });
  return url;
}

type PostNewDiagramMemo = Omit<
  MemoWithPathCreate,
  "files" | "mentionedUsers"
> & {
  files: File[];
} & {
  mentionedUsers: MentionUser[];
};

export async function fetchDiagramMemos(
  shipId: string,
  diagramId: string,
  page: number
) {
  const webapi = await getAuthedWebapi();

  const { memos, count } = await webapi.getShipsFShipNoDiagramsDiagramIdMemos({
    fShipNo: shipId,
    diagramId,
    page,
    limit: diagramMemoPageSize,
  });

  const models = memos.map(
    (x): Memo => {
      const comments = x.comments.map(
        (base): Comment => {
          if (base.isDeleted) return { ...base, isDeleted: true };

          const isUnread = isUnreadMemoMention(
            base.id,
            base.mentionedUsers!,
            base.updatedAt,
            diagramId,
            undefined,
            undefined,
            x.id
          );

          return {
            ...base,
            isDeleted: false,
            isUnreadMention: isUnread,
            files: base.files!.map((f) => ({ ...f, available: true })),
          };
        }
      );
      const base = { ...x, comments };
      if (x.isDeleted) return { ...base, isDeleted: true };

      const isUnread = isUnreadMemoMention(
        x.id,
        x.mentionedUsers!,
        x.updatedAt,
        diagramId
      );

      return {
        ...base,
        title: x.title!,
        detail: x.detail!,
        instances: deserializeDrawingInstances(x.path!),
        isDeleted: false,
        isUnreadMention: isUnread,
        files: x.files!.map((f) => ({ ...f, available: true })),
      };
    }
  );

  store.dispatch(
    actions.ship.diagramMemosReceived({ memos: models.map(memoToJson), count })
  );
}

/**
 * メモ作成処理
 *
 * オンライン: API 実行
 * オフライン: 下書き作成
 *
 * @param shipId 船舶番号
 * @param diagramId 図面ID
 * @param data 登録内容
 * @param key 下書き識別子
 */
export async function postNewDiagramMemo(
  shipId: string,
  diagramId: string,
  data: PostNewDiagramMemo,
  key: Draft["key"] = ""
) {
  const { setting, draft } = store.getState();
  const draftMemo = selectors.draft.diagramMemosSelectors.selectById(
    draft.diagramMemos,
    key
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );

  if (fulfilledConnect) {
    const webapi = await getAuthedWebapi();

    // 添付ファイルを全てアップロードする
    const uploadedFiles = await Promise.all(
      data.files.map((file) =>
        uploadAttachmentFile(file, () => webapi.getMemosFileUploadUrl())
      )
    );

    // メモデータを作成する
    await webapi.postShipsFShipNoDiagramsDiagramIdMemos({
      fShipNo: shipId,
      diagramId,
      memoWithPathCreate: {
        ...data,
        files: uploadedFiles,
        mentionedUsers: data.mentionedUsers.map((user) => user.id),
      },
    });

    if (draftMemo)
      diagramDraft.deleteDiagramDraftMemo(shipId, diagramId, draftMemo.key);
  } else {
    if (draftMemo) {
      diagramDraft.updateDiagramDraftMemo(
        shipId,
        diagramId,
        draftMemo.key,
        data
      );
    } else {
      diagramDraft.postNewDiagramDraftMemo(shipId, diagramId, data);
    }
  }
}

/**
 * メモ更新処理
 *
 * オンライン: API 実行
 * オフライン: 下書き更新
 *
 * @param shipId 船舶番号
 * @param diagramId 図面ID
 * @param memoId メモID
 * @param data 更新内容
 * @param key 下書き識別子
 */
export async function updateDiagramMemo(
  shipId: string,
  diagramId: string,
  memoId: Memo["id"],
  data: UpdateMemoData,
  key: Draft["key"] = ""
) {
  const { setting, ship, draft } = store.getState();
  const draftMemo = selectors.draft.diagramMemosSelectors.selectById(
    draft.diagramMemos,
    key
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );
  if (fulfilledConnect) {
    const webapi = await getAuthedWebapi();

    // 添付ファイルを全てアップロードする (新規のファイルのみ)
    const newFiles = data.files.filter(
      (x: any): x is File => x instanceof File
    );
    const uploadedFiles = await Promise.all(
      newFiles.map((file) =>
        uploadAttachmentFile(file, () => webapi.getMemosFileUploadUrl())
      )
    );

    // 以前の添付ファイルと合わせて、新しいファイルのリストを作成する
    const remainFiles: Array<{ id: number }> = data.files.filter(
      (x: any): x is { id: number } =>
        typeof x === "object" && x !== null && typeof x.id === "number"
    ) as any;
    const files = [...uploadedFiles, ...remainFiles];

    // 以前のメモデータを取得する
    const prevData = selectors.diagramMemo.selectById(
      ship.diagramMemos,
      memoId
    );
    if (!prevData) throw new Error("cannot find diagram memo to update");
    if (prevData.isDeleted)
      throw Error("cannot update memos that have already been deleted");

    // メモデータを更新する
    const path = serializeDrawingInstances(prevData.instances);
    await webapi.putShipsFShipNoDiagramsDiagramIdMemosMemoId({
      fShipNo: shipId,
      diagramId,
      memoId,
      memoWithPathUpdate: {
        ...prevData,
        ...data,
        path,
        files,
        mentionedUsers: data.mentionedUsers.map((user) => user.id),
      },
    });

    if (draftMemo)
      diagramDraft.deleteDiagramDraftMemo(shipId, diagramId, draftMemo.key);
  } else {
    if (draftMemo) {
      diagramDraft.updateDiagramDraftMemo(
        shipId,
        diagramId,
        draftMemo.key,
        data
      );
    } else {
      diagramDraft.postNewDiagramDraftMemo(shipId, diagramId, {
        ...data,
        id: memoId,
      });
    }
  }
}

/**
 * メモ削除処理
 *
 * オンライン: API 実行
 * オフライン: 下書き削除
 *
 * @param shipId 船舶番号
 * @param diagramId 図面ID
 * @param memoId メモID
 * @param key 下書き識別子
 */
export async function deleteDiagramMemo(
  shipId: string,
  diagramId: string,
  memoId: Memo["id"],
  key: Draft["key"] = ""
) {
  const { setting, draft } = store.getState();
  const draftMemo = selectors.draft.diagramMemosSelectors.selectById(
    draft.diagramMemos,
    key
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );
  if (fulfilledConnect) {
    const webapi = await getAuthedWebapi();
    const args = {
      fShipNo: shipId,
      diagramId,
      memoId,
    };

    if (draftMemo) {
      // 下書きが有る上に編集下書きの場合（新規作成の場合 API を実行しない）
      if (isEditedDraft(jsonToDraftMemo(draftMemo)))
        await webapi.deleteShipsFShipNoDiagramsDiagramIdMemosMemoId(args);
    } else {
      await webapi.deleteShipsFShipNoDiagramsDiagramIdMemosMemoId(args);
    }
  }

  // API 実行による登録後、同一の下書きがあれば削除する
  if (draftMemo)
    diagramDraft.deleteDiagramDraftMemo(shipId, diagramId, draftMemo.key);
}

/**
 * コメント作成処理
 *
 * オンライン: API 実行
 * オフライン: 下書き作成
 *
 * @param shipId 船舶番号
 * @param diagramId 図面ID
 * @param memoId メモID
 * @param comment 登録内容
 * @param key 下書き識別子
 */
export async function postNewDiagramMemoComment(
  shipId: string,
  diagramId: string,
  memoId: Memo["id"],
  comment: NewCommentData,
  key: Draft["key"] = ""
) {
  const { setting, draft } = store.getState();

  const draftComment = selectors.draft.diagramCommentsSelectors.selectById(
    draft.diagramComments,
    key
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );
  if (fulfilledConnect) {
    const webapi = await getAuthedWebapi();

    // 添付ファイルを全てアップロードする
    const files = await Promise.all(
      comment.files
        .filter((x: any): x is File => x instanceof File)
        .map((file) =>
          uploadAttachmentFile(file, () => webapi.getMemosFileUploadUrl())
        )
    );

    await webapi.postShipsFShipNoDiagramsDiagramIdMemosMemoIdComments({
      fShipNo: shipId,
      diagramId,
      memoId,
      commentCreate: {
        message: comment.message,
        files,
        mentionedUsers: comment.mentionedUsers.map((user) => user.id),
      },
    });

    if (draftComment)
      diagramDraft.deleteDiagramDraftComment(
        shipId,
        diagramId,
        draftComment.key
      );
  } else {
    if (draftComment) {
      diagramDraft.updateDiagramDraftComment(
        shipId,
        diagramId,
        draftComment.key,
        comment
      );
    } else {
      diagramDraft.postNewDiagramDraftComment(
        shipId,
        diagramId,
        memoId,
        comment
      );
    }
  }
}

/**
 * コメント更新処理
 *
 * オンライン: API 実行
 * オフライン: 下書き更新
 *
 * @param shipId 船舶番号
 * @param diagramId 図面ID
 * @param memoId メモID
 * @param commentId コメントID
 * @param data 更新内容
 * @param key 下書き識別子
 */
export async function updateDiagramMemoComment(
  shipId: string,
  diagramId: string,
  memoId: Memo["id"],
  commentId: Comment["id"],
  data: NewCommentData,
  key: Draft["key"] = ""
) {
  const { setting, draft, ship } = store.getState();

  const draftComment = selectors.draft.diagramCommentsSelectors.selectById(
    draft.diagramComments,
    key
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );
  if (fulfilledConnect) {
    const webapi = await getAuthedWebapi();

    // 添付ファイルを全てアップロードする (新規のファイルのみ)
    const newFiles = data.files.filter(
      (x: any): x is File => x instanceof File
    );
    const uploadedFiles = await Promise.all(
      newFiles.map((file) =>
        uploadAttachmentFile(file, () => webapi.getMemosFileUploadUrl())
      )
    );

    // 以前の添付ファイルと合わせて、新しいファイルのリストを作成する
    const remainFiles: Array<{ id: number }> = data.files.filter(
      (x: any): x is { id: number } =>
        typeof x === "object" && x !== null && typeof x.id === "number"
    ) as any;
    const files = [...uploadedFiles, ...remainFiles];

    // 以前のコメントデータを取得する
    const prevData = selectors.diagramMemo
      .selectById(ship.diagramMemos, memoId)
      ?.comments.find((c) => c.id === commentId);
    if (!prevData)
      throw new Error("cannot find diagram memo comment to update");
    if (prevData.isDeleted)
      throw Error("cannot update comments that have already been deleted");

    // メモデータを更新する
    await webapi.putShipsFShipNoDiagramsDiagramIdMemosMemoIdCommentsCommentId({
      fShipNo: shipId,
      diagramId,
      memoId,
      commentId,
      commentUpdate: {
        ...prevData,
        ...data,
        files,
        mentionedUsers: data.mentionedUsers.map((user) => user.id),
      },
    });

    if (draftComment)
      diagramDraft.deleteDiagramDraftComment(
        shipId,
        diagramId,
        draftComment.key
      );
  } else {
    if (draftComment) {
      diagramDraft.updateDiagramDraftComment(
        shipId,
        diagramId,
        draftComment.key,
        data
      );
    } else {
      diagramDraft.postNewDiagramDraftComment(shipId, diagramId, memoId, {
        ...data,
        id: commentId,
      });
    }
  }
}

/**
 * コメント削除処理
 *
 * オンライン: API 実行
 * オフライン: 下書き削除
 *
 * @param shipId 船舶番号
 * @param diagramId 図面ID
 * @param memoId メモID
 * @param commentId コメントID
 * @param key 下書き識別子
 */
export async function deleteDiagramMemoComment(
  shipId: string,
  diagramId: string,
  memoId: Memo["id"],
  commentId: Comment["id"],
  key: Draft["key"] = ""
) {
  const { setting, draft } = store.getState();
  const draftComment = selectors.draft.diagramCommentsSelectors.selectById(
    draft.diagramComments,
    key
  );
  const fulfilledConnect = selectors.setting.customSelectors.fulfilledConnectSelector(
    setting
  );
  if (fulfilledConnect) {
    const webapi = await getAuthedWebapi();
    const args = {
      fShipNo: shipId,
      diagramId,
      memoId,
      commentId,
    };

    if (draftComment) {
      if (isEditedDraft(jsonToDraftComment(draftComment)))
        await webapi.deleteShipsFShipNoDiagramsDiagramIdMemosMemoIdCommentsCommentId(
          args
        );
    } else {
      await webapi.deleteShipsFShipNoDiagramsDiagramIdMemosMemoIdCommentsCommentId(
        args
      );
    }
  }

  if (draftComment)
    diagramDraft.deleteDiagramDraftComment(shipId, diagramId, draftComment.key);
}

/**
 * re_export
 */
export const pullDraft = diagramDraft.pullDraft;

/**
 * HTML5 の download 属性を動的に処理
 *
 * @param url
 * @param fileName
 */
export async function downloadAttachmentFile(url: string, fileName: string) {
  const blob = await (await fetch(url)).blob();
  const objectUrl = URL.createObjectURL(blob);

  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  anchor.download = fileName;
  anchor.href = objectUrl;
  anchor.click();

  setTimeout(() => {
    URL.revokeObjectURL(objectUrl);
    anchor.remove();
  }, 300);
}
