import {
  ServiceReportSign as WebApiServiceReportSign,
  ServiceType as WebApiServiceType,
  ServiceReportSaveType,
  ServiceReportSignTypeEnum,
  OrderType,
  ServiceReportHeaderDetail,
} from "../webapi/generated";
import { ModifiedServiceOrder } from "./serviceOrder";
import { ModifiedWorkOrder } from "./workOrder";

export const SERVICE_REPORT_OFFLINE_STATUS = 0;

/**
 * サービスレポート署名の引数
 */
export type ServiceReportSign = WebApiServiceReportSign;

/**
 * サービスレポート署名の下書き
 */
export type ServiceReportSignatureDraft = Pick<
  ServiceReportHeaderDetail,
  | "technicianSignature"
  | "technicianSignedAt"
  | "customerSignature"
  | "customerRemarks"
  | "customerSignedAt"
> & {
  id?: number;
  key: string;
  fShipNo: string;
};

/**
 * サービスレポートの登録種別
 */
export const serviceReportSaveType = {
  TEMPORARY_SAVE: ServiceReportSaveType.NUMBER_1,
  SAVE: ServiceReportSaveType.NUMBER_2,
};

/**
 * サービスレポートの署名種別
 */
export const serviceReportSignatureType = {
  TECHNICIAN: ServiceReportSignTypeEnum.NUMBER_1,
  CUSTOMER: ServiceReportSignTypeEnum.NUMBER_2,
} as const;
export type ServiceReportSignature = typeof serviceReportSignatureType[keyof typeof serviceReportSignatureType];

/**
 * サービスレポートのオーダー種別
 */
export const serviceReportOrderType = {
  SERVICE_ORDER: OrderType.NUMBER_1,
  WORK_ORDER: OrderType.NUMBER_2,
} as const;

/**
 * サービスレポートのオーダー種別名
 */
export const serviceReportOrderTypeName = {
  [serviceReportOrderType.SERVICE_ORDER]: "SMS",
  [serviceReportOrderType.WORK_ORDER]: "SMS Next",
};
export type ServiceReportOrderType = typeof serviceReportOrderType[keyof typeof serviceReportOrderType];

/**
 * サービスレポートの作業種別
 */
export type ServiceType = WebApiServiceType;

/**
 * オーダー種別判定（サービスオーダー）
 *
 * @param query
 */
export function isServiceOrderType(query?: string) {
  return String(serviceReportOrderType.SERVICE_ORDER) === query;
}
/**
 * オーダー種別判定（ワークオーダー）
 *
 * @param query
 */
export function isWorkOrderType(query?: string) {
  return String(serviceReportOrderType.WORK_ORDER) === query;
}

/**
 * ワークオーダー判定
 * @param order オーダー情報
 */
export function isWorkOrder(
  order: ModifiedServiceOrder | ModifiedWorkOrder | MergedOrder
): order is ModifiedWorkOrder {
  return "workOrderNo" in order;
}

/**
 * オーダー統合版の型定義
 */
export type MergedOrder = Omit<ModifiedServiceOrder, "serviceOrderNo"> &
  Omit<ModifiedWorkOrder, "workOrderNo"> & {
    orderNo: string;
    orderType: ServiceReportOrderType;
    referenceNo2: string;
  };

/**
 * envのサービスレポートのデータ連携可否を返却
 */
export function canTransmit(): boolean {
  return process.env.REACT_APP_SERVICE_REPORT_CAN_TRANSMIT === "true";
}
