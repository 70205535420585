import { createMigrate } from "redux-persist";
import migrateV2 from "./migrations/v2";
import migrateV3 from "./migrations/v3";
import migrateV4 from "./migrations/v4";

// バージョン定義
export const PHASE = {
  V2: 2,
  V3: 3,
  V4: 4,
} as const;

/**
 * 永続対象を更新する場合は、変更必須
 */
export const CURRENT_VERSION = PHASE.V4;

const migrations = {
  [PHASE.V2]: migrateV2,
  [PHASE.V3]: migrateV3,
  [PHASE.V4]: migrateV4,
};

export default createMigrate(migrations as any, {
  debug: process.env.NODE_ENV === "development",
});
