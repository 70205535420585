import {
  ServiceReportDetailCreate,
  ServiceReportDetailDetail,
  ServiceReportDetailUpdate,
  serviceReportDetailStatus,
} from "../../models";
import store, { actions, selectors } from "../../store";
import { getAuthedWebapi } from "./custom-webapi";
import { updateHasServiceReportDrafts } from "./service-report-draft";

/**
 * APIから明細の詳細情報を取得し、Storeに保存する
 *
 * @param serviceReportId サービスレポートID
 * @param serviceReportDetailId 明細ID
 * @param versionId バージョンID
 */
export async function fetchStoreServiceReportDetail(
  fShipNo: string,
  serviceReportId: number,
  serviceReportDetailId: number,
  versionId?: number
) {
  const webapi = await getAuthedWebapi();
  const detail = await webapi.getServiceReportsServiceReportIdDetailsServiceReportDetailId(
    {
      serviceReportId: serviceReportId,
      serviceReportDetailId: serviceReportDetailId,
      versionId: versionId,
    }
  );

  const draft = selectors.draft.customSelectors.serviceReportDetailsByServiceReportIdDetailId(
    store.getState().draft,
    String(serviceReportId),
    String(serviceReportDetailId)
  );

  // 下書きの明細が存在する かつ 明細が最新版 かつ 明細のステータスがチェック完了の場合は下書きの明細を削除
  if (
    draft &&
    detail.isLatest &&
    detail.status === serviceReportDetailStatus.CHECK_COMPLETED
  ) {
    store.dispatch(actions.draft.serviceReportDetailRemoved(draft.key));
    updateHasServiceReportDrafts(fShipNo);
  }

  const convert: ServiceReportDetailDetail = {
    ...detail,
    constructions: detail.constructions.map((c) => {
      return { ...c, usedParts: c.usedParts || [] };
    }),
    id: String(detail.id),
    serviceReportId: String(serviceReportId),
    hasDraft:
      detail.status === serviceReportDetailStatus.CHECK_COMPLETED
        ? false
        : !!draft,
  };

  store.dispatch(actions.serviceReport.serviceReportDetailUpdate(convert));
}

/**
 * サービスレポート明細の登録
 *
 * @param serviceReportId サービスレポートID
 * @param params 明細登録内容
 */
export async function postNewServiceReportDetail(
  serviceReportId: number,
  params: ServiceReportDetailCreate
) {
  const webapi = await getAuthedWebapi();
  return webapi.postServiceReportsServiceReportIdDetails({
    serviceReportId,
    serviceReportDetailCreate: params,
  });
}

/**
 * サービスレポート明細の更新
 *
 * @param serviceReportId サービスレポートID
 * @param serviceReportDetailId サービスレポート明細ID
 * @param data 明細更新内容
 */
export async function updateServiceReportDetail(
  serviceReportId: number,
  serviceReportDetailId: number,
  data: ServiceReportDetailUpdate
) {
  const webapi = await getAuthedWebapi();
  return webapi.putServiceReportsServiceReportIdDetailsServiceReportDetailId({
    serviceReportId,
    serviceReportDetailId,
    serviceReportDetailUpdate: data,
  });
}

/**
 * サービスレポート明細の削除
 *
 * @param serviceReportId サービスレポートID
 * @param serviceReportDetailId 明細ID
 */
export async function deleteServiceReportDetail(
  serviceReportId: number,
  serviceReportDetailId: number
) {
  const webapi = await getAuthedWebapi();
  return webapi.deleteServiceReportsServiceReportIdDetailsServiceReportDetailId(
    {
      serviceReportId: serviceReportId,
      serviceReportDetailId: serviceReportDetailId,
    }
  );
}

/**
 * サービスレポート明細のチェック登録処理
 *
 * @param serviceReportId サービスレポートID
 * @param serviceReportDetailId 明細ID
 * @param isLast 最終チェック判定
 */
export async function updateCheckServiceReportDetail(
  serviceReportId: number,
  serviceReportDetailId: number,
  isLast: boolean
) {
  const webapi = await getAuthedWebapi();
  return webapi.postServiceReportsServiceReportIdDetailsServiceReportDetailIdCheck(
    {
      serviceReportId: serviceReportId,
      serviceReportDetailId: serviceReportDetailId,
      inlineObject: {
        isLast,
      },
    }
  );
}

/**
 * サービスレポート明細のチェック解除処理
 *
 * @param serviceReportId サービスレポートID
 * @param serviceReportDetailId 明細ID
 * @param isLast 最終チェック判定
 */
export async function uncheckServiceReportDetail(
  serviceReportId: number,
  serviceReportDetailId: number,
  isLast: boolean
) {
  const webapi = await getAuthedWebapi();
  return webapi.deleteServiceReportsServiceReportIdDetailsServiceReportDetailIdCheck(
    {
      serviceReportId: serviceReportId,
      serviceReportDetailId: serviceReportDetailId,
      inlineObject1: {
        isLast,
      },
    }
  );
}
